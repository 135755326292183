



import { Vue, Component } from 'vue-property-decorator'
import HelpIndex from '@/components/help/index.vue'

@Component({ components: { HelpIndex } })
export default class ViewHelp extends Vue {
}
