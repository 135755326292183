







































import { Component, Vue } from 'vue-property-decorator'
import HelpSign from '@/components/help/sign.vue'
import HelpNormal from '@/components/help/normal.vue'
import HelpFirmware from '@/components/help/firmware.vue'
import HelpDevice from '@/components/help/device.vue'
import HelpView from '@/components/help/view.vue'
import HelpLog from '@/components/help/log.vue'

@Component({
  components: {
    HelpSign, HelpNormal, HelpFirmware, HelpDevice, HelpView, HelpLog
  }
})
export default class HelpIndex extends Vue {
  tab = 0
  get xs () { return this.$vuetify.breakpoint.xs }
}
